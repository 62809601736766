<template>
  <div class="example"></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},

  methods: {},
  components: {},
  mounted() {},
};
</script>

<style scoped lang="less">
.example {
  width: 800px;
  height: 400px;
  margin: auto;
  margin-top: 100px;
}
</style>
